@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
	box-sizing:border-box;
	font-family:"Montserrat", sans-serif;
	color:#222222;
	font-size:16px;
}

html,
body {
	display:flex;
	width:100%;
	height:100%;
}

#root {
	display:flex;
	width:100%;
	height:100%;
}

.App {
	display:flex;
	width:100%;
	height:100%;
}

a {
	transition: all 0.15s ease-out 0s;
}

.btn {
	display:inline-block;
	border:1px solid #dddddd;
	border-radius:5px;
	text-decoration:none !important;
	cursor:pointer;
	padding:10px 30px;
	font-size:14px;
	font-weight:600;
	background-color:transparent;
	transition: all 0.15s ease-out 0s;
}

.btn:hover {
	background-color:#eeeeee;
}

.auth__page {
	width:100%;
	display:flex;
	padding:15px;
	overflow:auto;
	background-color:#f6f6f6;
}

.auth-form {
	box-shadow:0px 0px 10px rgba(100, 100, 100, 0.2);
	margin:auto;
	padding:30px 30px;
	border-radius:10px;
	max-width:400px;
	width:100%;
	background-color:#ffffff;
}

.form__title {
	font-size:27px;
	margin:0 0 25px 0;
	font-weight:700;
	/*text-align:center;*/
}

.form__item {
	
}

.form__item + .form__item {
	margin:15px 0 0 0;
}

.form__item-label {
	margin:0 0 7px 0;
	line-height:1;
	font-size:14px;
	font-weight:500;
	display:flex;
	justify-content:space-between;
	align-items:center;
	grid-gap:10px;
	gap:10px;
}

.form__item-label-error {
	color:#d10000;
	font-size:13px;
}

.form__item-input {
	width:100%;
	outline:none;
	border:1px solid #dddddd;
	padding:10px 15px;
	border-radius:3px;
	font-size:14px;
	font-weight:500;
}

.form__item-input-error {
	border:1px solid #d10000;
	background-color:#fff3f2;
}

.form__item-btns {
	display:flex;
	justify-content:space-between;
	align-items:center;
	grid-gap:10px;
	gap:10px;
	margin:30px 0 0 0 !important;
}

.form__item-link a {
	font-size:12px;
	color:#222222;
	font-weight:600;
	text-decoration:none;
	border-bottom:1px dashed #222222;
}

.form__error {
	margin:15px 0 0 0;
	border:1px solid #d10000;
	background-color:#fff3f2;
	padding:5px 10px;
	border-radius:3px;
	color:#d10000;
	font-size:14px;
	font-weight:500;
	text-align:center;
}


